<template>
  <!-- 用户端业务模块配置 -->
  <div class="passengerBusinessModelConfig">
    <Table
      style="width: 100%"
      :table-data.sync="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :drag-onoff="true"
      :operation="true"
      @dragFinsh="dragFinsh"
    >
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editRow(scope.scopeRow)"
          >编辑</el-button
        >
      </template>
    </Table>
    <!-- 编辑弹窗 -->
    <charterPopup
      v-if="showCharterPopup"
      :header-text="'编辑'"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="formRef"
        :model="formData"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="业务模块代码：">
          <span>{{ formData.moduleCode }}</span>
        </el-form-item>
        <el-form-item label="业务模块名称：">
          <span>{{ formData.name }}</span>
        </el-form-item>
        <el-form-item label="ICON：" prop="icon">
          <el-upload
            ref="elUpload"
            action
            list-type="picture-card"
            class="upload-demo"
            :auto-upload="false"
            :multiple="false"
            :on-change="imageSuccessFun"
            :on-remove="imageListRemove"
            :file-list="fileList"
            :limit="2"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过240kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="APP_ID：">
          <el-input
            v-model="formData.appId"
            placeholder="请输入APPID"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-switch
            v-model="formData.status"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
            :active-value="0"
            :inactive-value="1"
          ></el-switch>
        </el-form-item>
        <el-form-item label="是否优先显示：">
          <el-radio
            v-model="formData.priority"
            :label="0"
            :disabled="!!formData.status"
          ></el-radio>
        </el-form-item>
      </el-form>
    </charterPopup>
    <!-- icon大图预览 -->
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import {
  userBussinessAPI,
  updateUserBussinessAPI,
  dragRowAPI,
  uploadFileAPI,
  selectOneAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
export default {
  data() {
    return {
      dialogVisible: false, //是否显示预览大图
      dialogImageUrl: "", //预览大图URL
      showCharterPopup: false, //是否显示编辑弹窗
      formData: {},
      rules: {
        icon: [
          {
            required: true,
            validator: this.imgRules,
            trigger: "blur",
          },
        ],
      },
      imgBase64: "",
      imgMsg: "", //图片格式
      imgSize: 0,
      fileList: [],
      tableData: [],
      flag: false,
      titleName: [
        {
          title: "业务模块代码",
          props: "moduleCode",
        },
        {
          title: "业务模块名称",
          props: "name",
        },
        {
          title: "ICON",
          props: "icon",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: () => {
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.icon;
                },
              },
            });
          },
        },
        {
          title: "APP_ID",
          props: "appId",
        },
        {
          title: "是否显示",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status,
                activeValue: 0,
                inactiveValue: 1,
                disabled: this.flag && params.status === 0,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    id: params.id,
                    code: params.code,
                    status: val,
                    priority: params.priority,
                    sequence: params.sequence,
                    moduleCode: params.moduleCode,
                    appId: params.appId || null,
                    icon: params.icon,
                    name: params.name,
                  };
                  if (val) {
                    let showMenuList = this.tableData.filter(
                      (item) => item.status === 0
                    );
                    if (showMenuList.length > 2) {
                      this.flag = false;
                    } else {
                      this.flag = true;
                    }

                    if (params.priority == 0) {
                      obj.priority = 1;
                      let tepm = this.tableData.find(
                        (item) => item.status === 0 && item.id !== params.id
                      );
                      tepm &&
                        this.updateUserBussiness({ ...tepm, priority: 0 });
                    }
                  }
                  this.updateUserBussiness(obj);
                },
              },
            });
          },
        },
        {
          title: "是否优先显示",
          props: "priority",
          render: (h, params, index) => {
            return h("el-radio", {
              props: {
                value: params.priority,
                label: 0,
                disabled: params.status == 0 ? false : true,
              },
              on: {
                change: (val) => {
                  let obj = {
                    id: params.id,
                    code: params.code,
                    status: params.status,
                    priority: params.priority == 0 ? 1 : 0,
                    sequence: params.sequence,
                    moduleCode: params.moduleCode,
                    appId: params.appId || null,
                    icon: params.icon,
                    name: params.name,
                  };
                  this.updateUserBussiness(obj);
                },
              },
            });
          },
        },
      ],
    };
  },
  watch: {
    "formData.status"(val) {
      if (val == 1) {
        this.formData.priority = 1;
      }
    },
  },
  methods: {
    renderData() {
      userBussinessAPI().then((res) => {
        if (res.code == "SUCCESS") {
          const list = res.data || [];
          let showMenuList = list.filter((item) => item.status === 0);
          this.flag = true;
          if (showMenuList.length > 1) {
            this.flag = false;
          }
          this.tableData = [...list];
        }
      });
    },
    //修改业务
    updateUserBussiness(obj) {
      updateUserBussinessAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.clearForm();
          this.renderData();
        }
      });
    },
    dragFinsh(val) {
      dragRowAPI(val).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
        }
      });
    },
    //编辑
    editRow(row) {
      selectOneAPI({ code: row.code }).then((res) => {
        if (res.code == "SUCCESS") {
          this.showCharterPopup = true;
          this.formData = res.data;
          if (res.data.icon) {
            this.fileList = [{ url: host.imgURL + res.data.icon }];
          } else {
            this.fileList = [];
          }
        }
      });
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          new Promise((resolve, reject) => {
            // FormData 对象
            let form = new FormData();
            // 文件对象
            form.append("files", this.fileList[0].raw);
            uploadFileAPI(form).then((res) => {
              if (res.code == "SUCCESS") {
                this.formData.icon = res.data[0];
                resolve(this.formData);
              }
            });
          }).then((res) => {
            this.updateUserBussiness(res);
          });
        }
      });
    },
    // 取消按钮回调
    clearForm() {
      this.showCharterPopup = false;
      this.fileList = [];
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = 0;
      this.formData = {};
    },
    // 自定义图片规则
    imgRules(rule, value, callback) {
      if (this.imgBase64 == "") {
        if (this.fileList.length > 0 && this.fileList[0].size == undefined) {
          callback();
        } else {
          callback("请上传图片！");
        }
      } else {
        if (this.imgMsg === "jpg" || this.imgMsg === "png") {
          if (this.imgSize < 245760) {
            callback();
          } else {
            callback("图片大小超过限制！");
          }
        } else {
          callback("上传文件格式不正确！");
        }
      }
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList) {
      this.fileList = [file];
      let that = this;
      this.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = function (e) {
        that.imgBase64 = e.target.result; //读取的结果在result中
        that.$refs.formRef.validateField("icon");
      };
    },
    // 移除图片事件
    imageListRemove(file, fileList) {
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = "";
      this.fileList = [];
      this.$refs.formRef.clearValidate("icon");
    },
  },
  mounted() {
    this.renderData();
  },
};
</script>

<style scoped lang="scss">
.passengerBusinessModelConfig {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 20px 0px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: center;
  .imgPopup {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    img {
      max-width: 70%;
      max-height: 70%;
    }
  }
  /deep/.manual-content {
    width: 500px !important;
  }
}
/deep/.el-radio__label {
  font-size: 0;
}
</style>
